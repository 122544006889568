<template>
  <div class="coinater">
    <div class="fdleft">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{ project.name }}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">能效标识申报指标情况表</div>
        <div class="fdltxt">
          <div class="tableform">
            <el-form ref="form" :model="project" label-width="180px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目名称：">
                        {{ project.name }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="申报星级：">
                        <i
                          v-for="item in project.stars"
                          :key="item"
                          class="el-icon-star-on"
                        ></i>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-form ref="formtable" :model="formtable" label-width="180px">
            <div id="engerytable" class="engerytable">
              <table border="1" cellspacing="0" width="100%" style="table-layout:fixed;word-wrap:break-word;word-break:break-all">
                <tbody>
                  <!-- <tr>
                  <th scope="col">项目名称</th>
                  <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
                </tr>
                <tr>
                  <th scope="row">申报星级</th>
                  <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
                </tr> -->
                  <tr>
                    <th scope="row">申报指标</th>
                    <!-- <td colspan="2" scope="col">
                      <input v-model="formtable.sbIndex" type="text" />
                    </td> -->
                    <th colspan="7" scope="col">指标情况</th>
                    <!-- <td colspan="3" scope="col">
                      <input v-model="formtable.sbCircu" type="text" />
                    </td> -->
                  </tr>
                  <tr>
                    <th scope="row">相对节能率</th>
                    <td colspan="7" scope="col">
                      {{ formtable.relateEnergy }}
                    </td>
                  </tr> 
                  <tr>
                    <th scope="row">节能率</th>
                    <td colspan="2" scope="col">
                      <input v-model="formtable.buildEnergyRatio" type="text" />
                    </td>
                    <th colspan="2" scope="col">节能标准</th>
                    <td colspan="3" scope="col">
                      <span v-if="formtable.radio == 1">50%</span>
                      <span v-if="formtable.radio == 2">65%</span>
                      <span v-if="formtable.radio == 4">72%</span>
                      <span v-if="formtable.radio == 3">75%</span>
                      <!-- <span else>——</span> -->
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">基础项</th>
                    <td colspan="7" scope="col">
                      经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020标准要求，建筑节能率为{{
                        formtable.buildEnergyRatio
                      }}%，测评合格。
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">规定项</th>
                    <td colspan="7" scope="col">
                      经测评，该项目规定项{{
                        formtable.strip
                      }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020规定要求；
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">选择项</th>
                    <td colspan="7" scope="col">
                      经测评，该项目选择项{{ formtable.selectStrip }}条参评，加{{
                        formtable.grades
                      }}分；
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="6" scope="row">建筑热工性能</th>
                    <td>
                      屋面保温
                      <div style="color: #666">
                        （示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）
                      </div>
                    </td>
                    <td>
                      <span>{{formtable.keepwarm}}</span>
                    </td>
                    <td>
                      屋面传热系数[W/(m2·K)]
                      <div style="color: #666">（提示：保留两位小数）</div>
                    </td>
                    <td colspan="4">
                     {{formtable.heattransfer}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      外墙保温
                      <div style="color: #666">
                        （示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）
                      </div>
                    </td>
                    <td>{{formtable.roofWarm}}</td>
                    <td>
                      外墙传热系数[W/(m2·K)]
                      <div style="color: #666">（提示：保留两位小数）</div>
                    </td>
                    <td colspan="4">
                     {{formtable.outHeat}}
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      外窗型材
                      <div style="color: #666">
                        （示例：铝合金窗（6 +16A+6））
                      </div>
                    </td>
                    <td rowspan="4">
                     <span>{{formtable.exterProfile}}</span>
                    </td>
                    <td rowspan="2">
                      外窗传热系数[W/(m2·K)]
                      <div style="color: #666">（提示：保留两位小数）</div>
                    </td>
                    <td>东</td>
                    <td>{{formtable.outEaast}}</td>
                    <td>南</td>
                    <td>{{formtable.outSouth}}</td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td>{{formtable.outWest}}</td>
                    <td>北</td>
                    <td>{{formtable.outNorth}}</td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <span v-if="project.building_type==1"> 太阳得热系数</span>
                     <span v-if="project.building_type==2"> 遮阳系数SC</span>
                      <div style="color: #666">（提示：保留两位小数）</div>
                    </td>
                    <td>东</td>
                    <td>{{formtable.scEaast}}</td>
                    <td>南</td>
                    <td>{{formtable.scSouth}}</td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td>{{formtable.scWest}}</td>
                    <td>北</td>
                    <td>{{formtable.scNorth}}</td>
                  </tr>
                  <tr>
                    <th scope="row">空调冷热源
                      <div style="color: #666">（示例：冷水机组+燃气锅炉）</div>
                    </th>
                    <td colspan="7">
                      {{ formtable.cold_hot }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">是否采用生活热水节能</th>
                    <td colspan="7">
                      {{formtable.lifewater}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">单位建筑面积全年能耗（kWh/m2）</th>
                    <td colspan="7">
                     {{formtable.yearEnergy}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdtitlee">
        <div class="fdtsec">{{ project.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查历史记录</div>
        <div class="fdrtxt">
          <div v-for="item in shencha1" :key="item.id" class="fdrlist">
            <div class="fdrlcont">
              {{ item.idea }}
            </div>
            <div class="fdrtime">
              {{ item.created_at | formatDate("yyyy-MM-dd") }}
            </div>
          </div>
        </div>
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            :disabled="
              !edit ||
              (project.status >= 5 &&
                project.status != 15 &&
                project.status != 16)
            "
            v-model="idea"
            placeholder="请输入内容"
            style="height: 513px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group :disabled="!edit" v-model="pass">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <el-button type="primary" @click="pre_page">上一页</el-button>
          <el-button
            type="primary"
            :disabled="
              !edit ||
              (project.status >= 5 &&
                project.status != 15 &&
                project.status != 16)
            "
            @click="check"
            >保存</el-button
          >
          <!-- <el-button type="primary" @click="next_page">  下一页</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkSave, projectTableInfo } from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      idea: "",
      pass: "",
      project_id: "",
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],
      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,
      shencha1: [],
    };
  },
  mounted() {
    this.project_id = this.$route.query.project_id;
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },
  methods: {
    pre_page() {
      // let params = {
      //   idea: this.idea,
      //   pass: this.pass,
      //   project_id: this.project_id,
      //   type: 4,
      //   token: this.$store.state.token,
      // };
      // let that = this;
      // checkSave(params).then((res) => {
      //   console.log(res);
      //   // that.$message.success('保存成功');
      //   that.$router.push({
      //     path: "/formal/reportTable?project_id=" + this.project_id,
      //   });
      // });


        if (this.project.status<5 || this.project.status==15 || this.project.status==16) {
          this.$router.push({
            path: "/formal/reportTable?project_id=" + this.project_id,
          });
        } else if (this.project.status<15 || this.project.status==17 || this.project.status==18 || this.project.status==22 || this.project.status==23){
          this.$router.push({
            path: "/formal/totalTable?project_id=" + this.project_id,
          });
        }
    },
    check() {
      // if (!this.idea || this.idea=='' ){
      //   this.$message.warning("请填写审核内容")
      //   return false;
      // }
      if (!this.pass || this.pass=='' ){
        this.$message.warning("请选择是否通过")
        return false;
      }
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 4,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        this.edit = false;
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        if (that.project.status > 8) {
          that.edit = false;
        }
        that.formtable = that.project.detail;
        let checks = res.data.checks;
        that.shencha1 = checks
          .map((item) => {
            if (item.type == 4) {
              return item;
            }
          })
          .filter((d) => d);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 4) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.$nextTick(function () {
          let table = document.getElementById("engerytable");
          let inputs = table.getElementsByTagName("input");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }
        });
      });
    },
    goBack() {
      // this.$router.back()
      if (this.project.status<5 || this.project.status==15 || this.project.status==16){
        this.$router.push({
          path: "/formal/stepInformation?id=" + this.project_id,
        });
      }else if (this.project.status<15 || this.project.status==17 || this.project.status==18 || this.project.status==22 || this.project.status==23){
        this.$router.push({
          path: "/technology/techInformation?id=" + this.project_id,
        });
      }
    },
  },
};
</script>
<style scoped>
td {
  text-align: center;
  word-wrap:break-word;
  word-break:break-all;
}
/deep/.el-textarea > textarea {
  height: 513px;
}
/deep/.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
th {
  height: 40px;
}
.fdrtxt {
  height: 248px;
  overflow-y: scroll;
  border: 1px solid rgb(220, 223, 230);
  .fdrlist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dashed rgb(220, 223, 230);
    margin: 10px;
    padding: 3px 0;
    .fdrlcont {
      width: 60%;
    }
  }
}
.fdrtxt::-webkit-scrollbar {
  display: none;
}
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px 20px;
        height: 83vh;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
    .fdtsec {
      display: flex;
      flex-direction: row-reverse;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #666666;
    }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 13px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin: 15px 0;
      }

      .fdradio {
        margin: 15px 0;
        text-align: center;
      }

      .fdrbtn {
        text-align: center;
      }
    }
  }
}
.engerytable input {
  background-color: white;
}
</style>
